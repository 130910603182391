import React, { useState, useEffect } from 'react';
import axios from 'axios';

const ProductForm = () => {
    const [products, setProducts] = useState([]);
    const [productEntries, setProductEntries] = useState([{ product_id: '', size: '', cost: 0, sub_total: 0, tax_amt: 0, sizes: [] }]);
    const [customerInfo, setCustomerInfo] = useState({ name: '', email: '', mobile: '' });
    const [formErrors, setFormErrors] = useState({});
    const [totalCost, setTotalCost] = useState(0);

    useEffect(() => {
        axios.get('https://tjdstore.in/api/get_products.php')
            .then(response => setProducts(response.data))
            .catch(error => console.error('Error fetching products:', error));
    }, []);

    const validateForm = () => {
        let errors = {};
        let isValid = true;

        productEntries.forEach((entry, index) => {
            if (!entry.product_id) {
                errors[`product${index}`] = 'Please select a product';
                isValid = false;
            }
            if (!entry.size) {
                errors[`size${index}`] = 'Please select a size';
                isValid = false;
            }
        });

        if (!customerInfo.name.trim()) {
            errors.name = 'Name is required';
            isValid = false;
        }

        if (!customerInfo.email.trim()) {
            errors.email = 'Email is required';
            isValid = false;
        } else if (!/\S+@\S+\.\S+/.test(customerInfo.email)) {
            errors.email = 'Invalid email format';
            isValid = false;
        }

        if (!customerInfo.mobile.trim()) {
            errors.mobile = 'Mobile number is required';
            isValid = false;
        } else if (!/^\d{10}$/.test(customerInfo.mobile)) {
            errors.mobile = 'Mobile number must be 10 digits';
            isValid = false;
        }

        setFormErrors(errors);
        return isValid;
    };

    const handleProductChange = (index, event) => {
        const productId = event.target.value;
        const updatedEntries = [...productEntries];
        updatedEntries[index].product_id = productId;

        axios.get(`https://tjdstore.in/api/get_product_details.php?id=${productId}`)
            .then(response => {
                updatedEntries[index].cost = response.data.cost;
                updatedEntries[index].sub_total = response.data.sub_total;
                updatedEntries[index].tax_amt = response.data.tax_amt;
                updatedEntries[index].sizes = response.data.sizes;
                setProductEntries(updatedEntries);
                updateTotalCost(updatedEntries);
            })
            .catch(error => console.error('Error fetching product details:', error));
    };

    const handleSizeChange = (index, event) => {
        const size = event.target.value;
        const updatedEntries = [...productEntries];
        updatedEntries[index].size = size;
        setProductEntries(updatedEntries);
    };

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setCustomerInfo(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleAddProduct = () => {
        if (productEntries.length < 3) {
            setProductEntries([...productEntries, { product_id: '', size: '', cost: 0, sub_total: 0, tax_amt: 0, sizes: [] }]);
        }
    };

    const updateTotalCost = (entries) => {
        const total = entries.reduce((sum, entry) => sum + parseFloat(entry.cost || 0), 0);
        setTotalCost(total);
    };

    const handleSubmit = (event) => {
        event.preventDefault();

        if (!validateForm()) {
            return;
        }

        const formData = new FormData();
        formData.append('name', customerInfo.name);
        formData.append('email', customerInfo.email);
        formData.append('mobile', customerInfo.mobile);
        productEntries.forEach((entry, index) => {
            formData.append(`product_id${index + 1}`, entry.product_id);
            formData.append(`size${String.fromCharCode(97 + index)}`, entry.size); // sizea, sizeb, sizec
            formData.append(`cost${index + 1}`, entry.cost);
            formData.append(`sub_total${index + 1}`, entry.sub_total);
            formData.append(`tax_amt${index + 1}`, entry.tax_amt);
        });
        formData.append('total_cost', totalCost);

        axios.post('https://tjdstore.in/api/submit_customer.php', formData)
            .then(response => {
                if (response.data.success) {
                    alert('Customer information saved successfully');
                    // Clear the form
                    setProductEntries([{ product_id: '', size: '', cost: 0, sub_total: 0, tax_amt: 0, sizes: [] }]);
                    setCustomerInfo({ name: '', email: '', mobile: '' });
                    setFormErrors({});
                    setTotalCost(0);
                } else {
                    alert('Failed to save customer information');
                }
            })
            .catch(error => console.error('Error submitting form:', error));
    };

    return (
        <form onSubmit={handleSubmit}>
            {productEntries.map((entry, index) => (
                <div key={index}>
                    <label>Product {index + 1}:</label>
                    <select onChange={(e) => handleProductChange(index, e)} value={entry.product_id || ''}>
                        <option value="">Select a product</option>
                        {products.map(product => (
                            <option key={product.id} value={product.id}>{product.name}</option>
                        ))}
                    </select>
                    {formErrors[`product${index}`] && <span className="error">{formErrors[`product${index}`]}</span>}

                    {entry.product_id && (
                        <div>
                            <label>Sizes:</label>
                            {entry.sizes && entry.sizes.map(size => (
                                <div key={size}>
                                    <input 
                                        type="radio" 
                                        id={`${size}-${index}`} 
                                        name={`size${index}`} 
                                        value={size} 
                                        onChange={(e) => handleSizeChange(index, e)} 
                                        checked={entry.size === size} 
                                    />
                                    <label htmlFor={`${size}-${index}`}>{size}</label>
                                </div>
                            ))}
                            {formErrors[`size${index}`] && <span className="error">{formErrors[`size${index}`]}</span>}
                        </div>
                    )}
                </div>
            ))}

            <button type="button" onClick={handleAddProduct}>Add Another Product</button>

            <div>
                <label>Name:</label>
                <input type="text" name="name" value={customerInfo.name} onChange={handleInputChange} />
                {formErrors.name && <span className="error">{formErrors.name}</span>}
            </div>
            <div>
                <label>Email:</label>
                <input type="email" name="email" value={customerInfo.email} onChange={handleInputChange} />
                {formErrors.email && <span className="error">{formErrors.email}</span>}
            </div>
            <div>
                <label>Mobile:</label>
                <input type="text" name="mobile" value={customerInfo.mobile} onChange={handleInputChange} />
                {formErrors.mobile && <span className="error">{formErrors.mobile}</span>}
            </div>

            <div>
                <label>Total Cost: </label>
                <span>{totalCost}</span>
            </div>

            <button type="submit">Submit</button>
        </form>
    );
};

export default ProductForm;
